.card11 .card img {
  height: 400px;
  object-fit: cover;
  border-top-right-radius: 200px;
}

.card11 .card h3 {
  font-size: 30px;
  font-family: "Playfair Display", serif;
  margin: 20px 0 !important;
}

.card11 .card p {
  font-size: 20px !important;
  color: #5b5b5b;
  line-height: 30px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .card11 .card p {
    font-size: 16px !important;
    line-height: 25px;
    margin-bottom: 20px !important;
  }
  .card11 .card h3{
    margin: 20px 0 10px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
