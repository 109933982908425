.service_card label {
  font-family: "Playfair Display", serif;
  font-size: 60px;
  color: #595959;
  font-weight: 700;
  position: absolute;
  left: -50px;
  top: -30px;
}

.service_card .card {
  border: none;
  width: 75%;
  margin: 0 auto;
  margin-bottom: 50px;
}

.service_card img {
  height: 80px;
  width: auto;
  object-fit: contain;
  margin-left: 10px;
}

.service_card p {
  font-size: 17px !important;
  line-height: 25px;
  color: #000;
  margin-left: 30px;
  margin-top: 10px;
}

.service_card hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 0px;
  opacity: 1;
  margin-left: auto;
}

.service_card h2 {
  color: #d2ab66;
  margin-right: 20px;
  white-space: nowrap;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .service_card .card {
    width: 100%;
    margin-left: auto !important;
    margin-bottom: 0px;
  }
  .service_card img {
    margin-top: 85px;
    height: 60px;
  }
  .service_card label {
    left: -10px;
    font-size: 45px;
    top: 10px;
  }
  .service_card hr {
    display: none;
  }
  .service_card p {
    font-size: 15px !important;
    line-height: 22px;
    margin-top: 40px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
