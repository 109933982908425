.more hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 30px 0 0 30px;
  opacity: 1;
  margin-left: auto;
}

.more h2 {
  white-space: nowrap;
  margin-right: 20px;
  color: #dbb85c;
  text-align: center;
}

.more img {
  height: 600px;
  object-fit: cover;
}

.more .text {
  background-image: url("../../assets/Brands/black-shade.png");
  height: 600px;
  position: relative;
  margin-top: -600px;
}

.more .text h3 {
  position: absolute;
  bottom: 30px;
  width: 100%;
  text-align: center;
  font-size: 45px;
  color: #d2ab66;
  font-family: Didot;
  font-weight: 600;
  letter-spacing: 1px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .more h2 {
    font-size: 35px;
    width: 100%;
    white-space: nowrap !important;
    margin-right: 0px;
  }
  .more img {
    height: 250px;
  }
  .more .text {
    height: 250px;
    margin-top: -250px;
    margin-bottom: 15px;
  }
  .more .text h3 {
    bottom: 15px;
    font-size: 16px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .more .text h3 {
    bottom: 20px;
    font-size: 30px;
  }
  .more img {
    height: 500px;
  }
  .more .text {
    height: 500px;
    margin-top: -500px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
