#input-label {
  display: none;
}

.input-wrap {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 15px;
  border-radius: 7px;
  background: #fff;
  box-sizing: border-box;
}

.input-wrap i {
  font-size: 1rem;
  color: #000;
}

#product-search {
  font-size: 16px;
  color: #000;
  width: 100%;
  height: 100%;
  padding: 0 0 0 1rem;
  background: #fff;
  border: none;
  outline: none;
}

#product-search::placeholder {
  color: white;
  opacity: 0.7;
}

.fa-times{
  display: none !important;
}

.fa-times:hover {
  cursor: pointer;
}

.results-wrap {
  width: 50%;
  min-height: 11rem;
  max-height: auto;
  margin-top: 1rem;
  padding: 1rem;
  box-sizing: border-box;
  position: absolute;
  display: none;
}

.list-item {
  display: inline-flex;
  word-wrap: initial;
  list-style-type: none;
  font-size: 1.2rem;
  line-height: 1.5rem;
  text-transform: capitalize;
  margin-right: 0.5rem;
}

.list-item a {
  color: grey;
  text-decoration: none;
}

.list-item a:hover {
  color: tomato;
  transition: 200ms ease-in;
}

@media (max-width: 768px) {
  .input-wrap {
    width: 96%;
  }

  .results-wrap {
    width: 96%;
  }
}

.search-iconnav{
  color:grey;
  margin-left: 8px; /* Adjust as needed */
  cursor: pointer;
  font-size: 25px;
}

.itemcolor a{
  color:#fff;
}
