.button2 button {
    background-color: #d2ab66 !important;
    border:1px solid #d2ab66 !important;
    color: #000 !important;
    height: 48px !important;
    width: 170px !important;
    border-radius: 0px;
    font-size: 20px !important;
    font-family: Avenir;
    margin-bottom: 10px;
    font-family: "Playfair Display", serif;
    font-weight: 700;
    letter-spacing: 1px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center
  }
  
  .button2 button:hover {
    border: 1px solid #d2ab66;
    font-weight: 600;
  }

  
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .button2 button {
      font-size: 15px !important;
      height: 40px !important;
      margin: 10px auto;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
  .button2 button{
    font-size: 17px !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}