.event_main .event_card h2,
.event_main .past_events h2,
.event_details h2 {
  color: #dbb85c;
  white-space: nowrap;
}

.event_main hr,
.event_details hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 20px;
  opacity: 1;
}

.event_details .event_card hr {
  display: none;
}

.event_details .form1 h2 {
  text-align: left;
  white-space: initial;
}

.event_details .event_card h2 {
  display: none;
}

.event_details .event_card {
  padding-top: 0 !important;
  margin-top: 0 !important;
  padding-bottom: 40px;
}

.event_main .past_events img {
  height: 300px;
  object-fit: cover;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .event_details .banner {
    height: 40vh;
  }
  .event_details .banner img {
    height: 40vh !important;
  }
  .event_details .tc ul li {
    margin-left: 0;
    font-size: 15px;
  }
  .event_main .banner .banner-text {
    margin-top: -45px;
  }
  .event_main hr, .event_details hr{
    display: none;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
