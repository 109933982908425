.about-info .lease_info .row {
  display: none;
}

.about .about-sect .reverse .row {
  flex-direction: row-reverse;
}

.about .about-sect .reverse .text {
  margin-left: 80px;
}

.about .about-sect button {
  display: none;
}

.about .about-sect img {
  height: 650px;
}

.about .about-sect .card5 .text {
  height: 500px;
}

.about .card5 .text p {
  font-size: 18px !important;
}

.about .carousel1 h3 {
  background: none;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .about .about-sect .reverse .text {
    margin-left: 0;
  }
  .about .about-sect .card5 .text {
    height: fit-content;
  }
  .about .about-sect img {
    height: 350px;
  }
  .about .card5 .text p {
    font-size: 16px !important;
    margin: 15px 0 5px !important;
  }
  .about .card5 .text h2 {
    font-size: 25px !important;
  }
  .about .offers img {
    height: 240px !important;
    object-fit: cover;
  }
  .about .carousel1 h2 {
    margin-top: 0;
  }
  .about-info .lease_info h2{
    white-space: normal !important;
  }
  .about-info .lease_info hr{
    display: none;
  }
  .about_card .text h2{
    white-space: nowrap !important
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  .about .about-sect .reverse .text {
    margin-left: 35%;
  }
}

@media (min-width: 2000px) {
  .about .about-sect .reverse .text {
    margin-left: 43% !important;
  }
}
