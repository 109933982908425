.gift .gift-card .text {
  border: 1px solid #d2ab66;
  text-align: center;
  padding: 20px 0;
}

.gift .gift-card .text h3 {
  font-size: 25px;
  font-family: "Playfair Display", serif;
  font-weight: 700;
}

.gift .gift-image img {
  height: 700px;
  object-fit: cover;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .gift .gift-card .text {
    padding: 10px 0;
  }
  .gift .gift-card .card{
    margin-bottom: 10px;
  }
  .gift .gift-image img {
    height: 380px;
  }
  .gift .gift-card .text h3{
    font-size: 20px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
