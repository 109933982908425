.button1 button{
    font-size: 23px !important;
    font-weight: 700;
    color: #000 !important;
    font-family: "Playfair Display", serif !important;
    display: flex;
    border: 1px solid #dbb85c !important;
    width:auto !important;
    padding:0 30px !important;
    height:65px !important;
    justify-content: center;
    align-items: center;
    margin: 20px auto;
    letter-spacing: 1px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .button1 button {
        font-size: 15px !important;
        height: 45px !important; 
        margin: 10px auto;
    }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
    .button1 button{
        font-size: 20px !important;
        height:55px !important;
        margin:10px auto
    }
}
 
/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
