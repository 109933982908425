.brand_detail_head {
}

.brand_detail_head h2 {
  color: #000 !important;
  font-size: 54px;
  /* font-weight: 600; */
}

.brand_detail_head h4 {
  font-size: 18px;
  font-family: Avenir;
  color: #d4ac65;
  font-weight: 600;
  display: flex;
}

.brand_detail_head span {
  color: #5b5b5b;
  margin: -1px 15px;
}

.brand_detail_head h5 {
  font-size: 23px;
  font-family: Avenir;
  color: #ffffff;
  border: 3px solid #ffffff;
  padding: 10px;
  width: 280px;
  text-align: center;
  font-weight: 600;
}

.brand_detail_head .logo-card {
  margin-top: -200px;
  background-color: transparent;
  position: relative;
  z-index: 10;
}

.brand_detail_head .logo-card img {
  height: 400px;
  object-fit: contain;
}

.brand_detail_head h3 {
  font-size: 50px;
  color: #dbb85c;
  font-family: 'Playfair Display', serif;
  margin-right: 10px;
  white-space: nowrap;
  text-transform: uppercase;
}

.brand_detail_head hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 20px;
  opacity: 1;
}

.brand_detail_head h6 {
  font-size: 26px;
  font-family: Avenir;
  color: #5b5b5b;
  text-align: center;
  font-weight: 600;
}

.brand_detail_head p {
  font-size: 20px;
  font-family: Avenir;
  text-align: justify;
  line-height: 30px;
  color: #5b5b5b;
  margin-top: 10px;
}

.brand_detail_head a p {
  color: #5b5b5b;
  text-align: center;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .brand_detail_head .logo-card img {
    height: 110px;
  }
  .brand_detail_head .logo-card {
    margin-top: -40px;
  }
  .brand_detail_head h4 {
    font-size: 14px;
  }
  .brand_detail_head span {
    margin: -5px 10px;
  }
  .brand_detail_head h5 {
    font-size: 12px;
    padding: 8px;
    width: 145px;
  }
  .brand_detail_head h3 {
    font-size: 25px;
  }
  .brand_detail_head p {
    font-size: 16px;
    line-height: 25px;
    margin-top: 0px;
  }
  .brand_detail_head h6 {
    font-size: 18px;
  }
  .brand_detail_head h2 {
    font-size: 28px !important;
    text-align: left;
    margin-top:20px
  }
  .brand_detail_head hr{
    margin:15px 0 0 0px !important
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .brand_detail_head .logo-card img {
    height: 290px;
  }
  .brand_detail_head .logo-card {
    margin-top: -140px;
  }
  .brand_detail_head h2 {
    font-size: 40px !important;
  }
  .brand_detail_head h3 {
    font-size: 40px;
  }
  .brand_detail_head h4 {
    font-size: 16px;
  }
  .brand_detail_head span {
    margin: -4px 10px;
  }
  .brand_detail_head h5 {
    font-size: 17px;
    width: 215px;
  }
  .brand_detail_head h3{
    white-space: nowrap;
  }
  .brand_detail_head p{
    font-size: 16px !important;
    line-height: 25px;
  }
  .brand_detail_head h6 {
    font-size: 20px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
