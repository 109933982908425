.location .details img {
  height: 70px;
  border-radius: 8px;
  width: 60px;
  margin-right: 15px;
}

.location .details .text h3 {
  font-size: 20px;
  font-family: "Avenir";
  color: #656565;
  font-weight: 600;
  margin-bottom: 15px !important;
}

.location .details .text p,
.location .details .text a {
  font-size: 18px !important;
  color: #000;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .location .details .text h3 {
    font-size: 18px !important;
    margin-bottom: 5px !important;
  }
  .location .details .text p,
  .location .details .text a {
    font-size: 15px !important;
  }
  .location .details img {
    height: 50px;
    width: 50px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
