.category_card .category-filter {
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    height: 60px;
  }
  
  .category_card .category-filter #input-label {
    display: none;
  }
  
  .category_card .category-filter .input-wrap {
      width:400px;
    border:3px solid #d2ab66;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 15px;
    border-radius: 0px;
    background: #fff;
    box-sizing: border-box;
  }
  
  .category_card .category-filter .input-wrap i {
    font-size: 1rem;
    color: #000;
  }
  
  .category_card .category-filter #product-search {
    font-size: 16px;
    color: #000;
    width: 100%;
    height: 100%;
    padding: 0 0 0 1rem;
    background: #fff;
    border: none;
    outline: none;
    font-weight: 600;
  }
  
  .category_card .category-filter #product-search::placeholder {
    color: white;
    opacity: 0.7;
  }
  
  .category_card .category-filter .fa-times:hover {
    cursor: pointer;
  }
  
  .category_card .category-filter .results-wrap {
    width: 50%;
    min-height: 11rem;
    max-height: auto;
    margin-top: 1rem;
    padding: 1rem;
    box-sizing: border-box;
  }
  
  .category_card .category-filter .list-item {
    display: inline-flex;
    word-wrap: initial;
    list-style-type: none;
    font-size: 1.2rem;
    line-height: 1.5rem;
    text-transform: capitalize;
    margin-right: 0.5rem;
  }
  
  .category_card .category-filter .list-item a {
    color: grey;
    text-decoration: none;
  }
  
  .category_card .category-filter .list-item a:hover {
    color: tomato;
    transition: 200ms ease-in;
  }
  
  @media (max-width: 768px) {
      .category_card .category-filter .input-wrap {
      width: 100%;
      height: 40px;
    }
  
    .category_card .category-filter .results-wrap {
      width: 100%;
    }
  }
  