.card9 .card {
  border: none;
}

.card9 .card img {
  height: 300px;
  object-fit: cover;
  object-position: top;
}


.card9 .card .text {
  /* height: 300px; */
  position: absolute;
  bottom: 0px;
  text-align: center;
  width: 100%;
}

.card9 .card .text h3 {
  padding: 0 20px;
  color: #fff;
  font-family: Coldiac;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .card9 .card img {
    height: 200px;
    margin-bottom: 10px;
  }
  .card9 .card .text h3 {
    font-size: 18px !important;
  }
  .card9 .card .text{
    bottom:5px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
