.card13 .card {
  border: none;
}

.card13 .card img {
  height: 300px;
  object-fit: cover;
  border-radius: 20px;
  margin: 30px 0;
}

.card13 .card h4 {
  font-size: 25px;
}

.card13 label {
  color: #777777;
  font-size: 13px;
}

.card13 span {
  margin: -2px 10px;
  color: #777777;
}

.card13 label svg {
  margin: -3px 10px 0 0;
}

.card13 p {
  font-size: 18px !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .card13 .card h4 {
    font-size: 18px;
  }
  .card13 .card img {
    height: 220px;
    margin: 15px 0;
  }
  .card13 span {
    margin: -5px 5px;
  }
  .card13 label {
    font-size: 12px;
  }
  .card13 label svg {
    margin: -3px 6px 0 0;
  }
  .card13 p {
    margin-bottom: 20px !important;
    font-size: 14px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .card13 label {
    font-size: 12px;
  }
  .card13 span {
    margin: -5px 10px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
