.collection .sect1 img {
  height: 380px;
  object-fit: cover;
  /* margin-bottom: 20px; */
}

.collection .sect2 img {
  height: 380px;
  object-fit: cover;
}

.collection .sect3 img {
  height: 764px;
  object-fit: cover;
}

.collection hr {
  border-top: 4px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 30px 0 0 20px;
  opacity: 1;
}

.collection h2 {
  color: #dbb85c;
  text-align: center;
  white-space: nowrap;
}

.collection h3 {
  background-color: rgba(0, 0, 0, 0.7);
  font-size: 25px;
  font-family: Avenir;
  color: #fff;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-top: -70px;
  width: 100%;
  margin-bottom: 0;
}

.collection .carousel-control-prev,
.collection .carousel-control-next {
  display: none;
}

.collection .carousel-indicators [data-bs-target] {
  width: 15px;
  height: 15px;
}

.collection .carousel-indicators [data-bs-target] {
  width: 12px;
  height: 12px;
  border: 2px solid #d2ab66;
  margin-right: 8px;
  border-radius: 50%;
}

.collection .carousel-indicators {
  bottom: -55px;
  display: none;
}

.collection .carousel-indicators .active {
  background-color: #d2ab66 !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .collection h2 {
    font-size: 35px;
    white-space: nowrap !important;
  }
  .collection .sect1 img,
  .collection .sect2 img,
  .collection .sect3 img {
    height: 250px;
  }
  .collection h3 {
    font-size: 16px !important;
    height: 50px;
  }
  .collection .carousel-indicators {
    bottom: -33px;
  }
  .collection .carousel-indicators [data-bs-target] {
    width: 8px;
    height: 8px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .collection h3{
    font-size: 20px !important;
    height: 55px;
    margin-top: -55px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
