
.launch .coming_soon h3{
    background: none;
}

.launch .coming_soon .carousel-indicators{
    /* display: none; */
}

.launch .form1{
    margin-top: -120px;
}

.launch .coming_soon .carousel1{
    padding-top: 0 !important;
}