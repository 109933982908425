.services .banner img,
.services .banner {
  height: 70vh !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .services .banner,
  .services .banner img {
    height: 40vh !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
