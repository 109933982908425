.search_result .navbar {
  background-color: #000 !important;
}

.search_result .search-detail {
  padding-top: 220px;
  padding-bottom: 70px;
}

.search_result .search-detail img {
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  padding: 20px;
}
.search_result .search-detail h3 {
  margin-bottom: 10px !important;
  font-size: 28px;
  font-weight: 700;
}
.search_result .search-detail p {
  font-size: 18px !important;
  margin-bottom: 10px !important;
}

.search_result .search-detail a {
  color: #000;
  border-bottom: 1px solid #000;
  padding-bottom: 5px;
  font-weight: 600;
}

.search_result hr {
  margin: 10px 0 0;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .search_result .search-detail {
    padding-top: 100px;
    padding-bottom: 50px;
  }
  .search_result .search-detail a {
    font-size: 12px;
  }
  .search_result .search-detail img{
    height: 120px;
  }
  .w-75 {
    width: 100% !important;
  }
  .search_result .search-detail p {
    font-size: 16px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
