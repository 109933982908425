.blog_head img {
  height: 500px;
  object-fit: cover;
  margin: 30px 0;
}

.blog_head label {
  color: #777777;
  font-weight: 600;
  font-size: 17px;
}

.blog_head span {
  margin: 0px 18px;
}

.blog_head label svg {
  margin: -3px 10px 0 0;
}

.blog_head p {
  font-size: 20px !important;
  margin: 30px 0;
  line-height: 35px;
}

.blog_more hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 0px;
  opacity: 1;
  margin-left: auto;
}

.blog_more h2 {
  color: #d2ab66;
  font-size: 55px;
  /* font-weight: 600; */
  margin-right: 15px;
  white-space: nowrap;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .blog_head img {
    height: 300px;
    margin: 25px 0;
  }
  .blog_head span {
    margin: -3px 5px;
  }
  .blog_head label svg {
    margin: -3px 5px 0 0;
  }
  .blog_head label {
    font-size: 12px;
  }
  .blog_head p {
    font-size: 16px !important;
    margin: 10px 0;
    line-height: 25px;
  }
  .blog_more hr{
    display: none;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
