.service_tab #tabs button {
  padding: 0;
  width: 100%;
}

.service_tab #tabs ul {
  padding-left: 0;
  margin-bottom: 0;
}

.service_tab #tabs ul li {
  list-style-type: none;
}

.service_tab #tabs menu h2 {
  font-size: 28px !important;
  color: #fff;
  margin-bottom: 0;
  z-index: 1;
  letter-spacing: 2px;
  width: 100%;
}

.service_tab #tabs menu .line-h2 {
  border-left: 1px solid #fff;
  border-right: 1px solid #fff;
}

.service_tab #tabs menu {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  margin: 0px;
  padding-left: 0;
  background-color: rgb(40, 40, 40);
  height: 100px;
  align-items: center;
}

.service_tab #tabs button {
  font-size: 20px;
  color: #949494;
  display: flex;
  font-family: Avenir;
  font-weight: 600;
  background-color: transparent;
  border: none;
  justify-content: center;
}

.service_tab #tabs button.active h2 {
  color: #d2ab66;
}

.service_curb .curb_cards .card {
  border: none;
  text-align: center;
}

.service_curb .curb_cards .card img {
  height: 80px;
  object-fit: contain;
}

.service_curb .curb_cards .card h4 {
  font-size: 25px;
  color: #272727;
  margin: 30px 0 20px !important;
}

.service_curb .curb_cards .card p {
  font-size: 20px !important;
  color: #5b5b5b;
  line-height: 30px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .service_tab #tabs menu h2 {
    margin-top: 75px;
    font-size: 18px !important;
    width: 100%;
    border: none !important;
    line-height: 30px;
  }
  .service_tab #tabs menu {
    height: 120px;
  }
  .service_tab #tabs {
    /* margin-top: 75px; */
    z-index: 1000;
  }
  .service_tab #tabs button {
    justify-content: unset;
  }
  .service_curb .curb_cards .card img {
    height: 60px;
  }
  .service_curb .curb_cards .card h4 {
    font-size: 18px;
    margin: 30px 0 10px !important;
  }
  .service_curb .curb_cards .card p {
    font-size: 15px !important;
    line-height: 22px;
    margin-bottom: 20px !important;
  }
  .mall_services .nav-pills .nav-link {
    font-size: 20px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1199.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
