.media_news img {
  height: 500px;
  object-fit: cover;
}

.media_news .text {
  position: absolute;
  top: 0;
  height: 500px;
  padding: 50px;
}

.media_news hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 0px;
  opacity: 1;
  margin-left: auto;
}

.media_news h2 {
  color: #d2ab66;
  font-size: 55px;
  /* font-weight: 600; */
  margin-right: 15px;
  white-space: nowrap;
}

.media_news h3 {
  color: #fff;
  font-family: Avenir;
  font-weight: 600;
  font-size: 35px;
  line-height: 50px;
}

.media_news label {
  color: #fff;
  margin: 20px 0;
}

.media_news span {
  color: #fff;
  margin: 18px;
}

.media_news label svg {
  margin: -3px 10px 0 0;
}

.media_news p {
  color: #fff;
  font-size: 18px !important;
  line-height: 30px;
}

.media_news .carousel-indicators [data-bs-target] {
  border-radius: 50%;
  width: 10px;
  height: 10px;
}

.media_news .carousel-control-prev,
.media_news .carousel-control-next {
  display: none;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .media_news h3 {
    line-height: 25px;
    font-size: 20px !important;
  }
  .media_news .text {
    padding: 20px;
  }
  .media_news label {
    margin: 15px 0;
    font-size: 13px;
  }
  .media_news span {
    margin: 11px;
  }
  .media_news p {
    font-size: 16px !important;
    line-height: 25px;
  }
  .media_news img {
    height: 400px;
  }
  .media_news h2{
    white-space: nowrap !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
