.help hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 40px;
  opacity: 1;
  margin-left: auto;
}

.help h2 {
 white-space: nowrap;
 margin-right: 20px;
  color: #dbb85c;
  text-align: center;
}

.help img {
  height: 600px;
  width: 100%;
  object-fit: cover;
  border-radius: 0 150px;
}

.help .text {
  height: 600px;
  /* display: flex; */
  align-items: center;
  padding: 0 50px;
}

.help .gborder {
  border: 5px solid #d2ab66;
  height: 540px;
  width: 640px;
  position: absolute;
  margin-top: -570px;
  margin-left: 30px;
}

.help h3 {
  font-size: 30px;
  color: #d2ab66;
  font-family: 'Playfair Display', serif;
  text-align: center;
  text-transform: uppercase;
  font-weight: 600;
  /* white-space: nowrap; */
}

.help p {
  line-height: 35px;
  color: #0d0d0d;
  margin: 30px 0px 40px;
}

.help button {
  font-size: 20px;
  font-weight: 600;
  color: #000;
  font-family: Didot;
  display: flex;
  border: 3px solid #dbb85c;
  width: 350px;
  height: 60px;
  justify-content: center;
  align-items: center;
  letter-spacing: 1px;
  margin: 40px auto 0;
  border-radius: 0;
}

.help button:hover {
  border: 3px solid #dbb85c;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .help h2 {
    font-size: 35px;
    margin: 0 auto;
    white-space: nowrap !important
  }
  .help h3 {
    font-size: 26px;
  }
  .help p {
    font-size: 15px;
    line-height: 25px;
    margin: 15px 0px 0px;
  }
  .help img {
    height: 350px;
    width: 100%;
  }
  .help .gborder {
    height: 350px;
    width: 310px;
    margin-top: -350px;
    margin-left: 20px;
  }
  .help .text {
    height: fit-content;
    margin-top: 20px;
    padding: 0;
  }
  .help button {
    font-size: 17px;
    width: auto;
    height: 50px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .help img {
    /* width: 445px; */
    height: 430px;
  }
  .help .gborder{
    width:380px;
    height: 450px;
    margin-top: -440px;
  }

  .help .text{
    display: block;
    height: fit-content;
  }
  .help h3{
    white-space: unset;
  }
  .help p {
    font-size: 17px !important;
    line-height: 28px;
  }
  .help button{
    font-size: 18px !important;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
  
}
