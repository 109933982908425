.contact_form hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 70px;
  opacity: 1;
  margin-left: auto;
}

.contact_form h2 {
  color: #d2ab66;
  font-size: 55px;
  /* font-weight: 600; */
  margin-right: 15px;
  white-space: nowrap;
}

.contact_form .form {
  width: 75%;
  margin: 0 auto;
}

.contact_form .form input::placeholder {
  font-size: 18px;
}

.contact_form .form input {
  border: 3px solid #d2ab66;
  margin-bottom: 20px;
  padding: 10px 15px;
}

.contact_form .form textarea {
  border: 3px solid #d2ab66;
  margin-bottom: 30px;
  padding: 10px 15px;
}

.contact_form .form button {
  border-radius: 20px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .contact_form .form input::placeholder {
    font-size: 14px;
  }
  .contact_form .form input,
  .contact_form .form textarea {
    margin-bottom: 15px;
    padding: 5px 10px;
  }
  .contact_form .form {
    width: 90%;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
