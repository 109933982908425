.press_release hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 0px;
  opacity: 1;
  margin-left: auto;
}

.press_release h2 {
  color: #d2ab66;
  font-size: 55px;
  /* font-weight: 600; */
  margin-right: 15px;
  white-space: nowrap;
}

.press_release .owl-theme .owl-dots {
  margin-top: 40px;
}

.press_release .owl-theme .owl-dots .owl-dot span {
  /* background-color: #d2ab66; */
}

.press_release .owl-nav {
  display: none;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .press_release .owl-theme .owl-dots {
    margin-top: 00px;
  }
  .press_release h2{
    white-space: nowrap !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
