.card12 .card {
  border: none;
}
.card12 .card .text {
  width: 90%;
  margin: 0 auto 20px;
  position: relative;
  padding: 30px;
  box-shadow: 0px 4px 20px 0 #d2ab66;
  text-align: center;
  margin-top: -50px;
  background-color: #fff;
}

.card12 .card .text p {
  font-size: 18px !important  ;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .card12 .card .text {
    width: 100%;
    margin: 0 auto 0px;
    padding: 20px 0px 0;
    margin-top: 0px;
  }
  .card12 .card .text p {
    font-size: 16px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
