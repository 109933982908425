.faq hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 0px;
  opacity: 1;
  margin-left: auto;
}

.faq h2 {
  color: #d2ab66;
  /* font-weight: 600; */
  margin-right: 20px;
  white-space: nowrap;
}

.faq .accordion .accordion-item {
  border: none;
}

.accordion-item .accordion-button {
  background: none;
  border-bottom: 2px solid #d2ab66;
  padding: 25px 0;
  font-family: Avenir;
  font-size: 22px !important;
  border-radius: 0 !important;
}

.accordion-item .accordion-button:focus {
  box-shadow: none;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .faq .container {
    padding: 0;
  }
  .faq h2 {
    margin-right: 0;
  }
  .accordion-item .accordion-button {
    padding: 15px 0;
    font-size: 15px !important;
  }
  .faq hr{
    display: none;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
