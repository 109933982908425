.card3 .card {
  height: 300px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #dbb85c;
  margin: 25px 0px;
  border-radius: 0;
}

.card3 .card h3 {
  font-size: 17px;
  color: #747474;
  text-align: center;
}

.card3 .card h4 {
  font-size: 17px;
  color: #484848;
  margin-bottom: 15px !important;
  text-align: center;
}


.card3 .card h4 svg {
  color: #d4ac65;
  margin-right: 5px;
}

.card3 .card img {
  width: 170px;
  height: 140px;
  margin: 20px auto;
  display: flex;
  object-fit: contain;
  transition: transform 0.2s;
  transform: scale(1);
}
.card3 .card:hover img{
  transform: scale(1.1);
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .card3 .card h4 {
    font-size: 14px;
  }
  .card3 .card {
    height: 210px;
    padding-top:15px;
    margin-bottom: 10px;
    margin-top: 0;
  }
  .card3 .card img {
    height:100px;
    width:100px;
    object-fit: cover;
    display: flex;
    margin: 10px auto;
  }
  .card3 .card h3 {
    font-size: 14px !important;
    height: 20px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .card3 .card img {
    display: flex;
    height: 100px;
    width: 100px;
    margin: 0 auto;
  }
  .card3 .card h4 {
    font-size: 18px;
    margin-bottom: 10px !important;
  }
  .card3 .card {
    height: 200px;
  }
  .card3 .card h3 {
    font-size: 14px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
