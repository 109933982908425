.lease_info hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 0px;
  opacity: 1;
  margin-left: auto;
}

.lease_info h2 {
  color: #d2ab66;
  /* font-weight: 600; */
  margin-right: 20px;
  white-space: nowrap;
}

.lease_info p {
  font-size: 18px !important;
  line-height: 28px;
}

.lease_info .row {
  border: 3px solid #d2ab66;
  border-radius: 20px;
  width: 90%;
  margin: 70px auto 0;
  padding: 50px;
}

.lease_info h3 {
  margin-bottom: 10px !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .lease_info p {
    font-size: 16px !important;
    line-height: 25px;
    margin-top: 10px;
    margin-bottom: 10px !important;
  }
  .lease_info .row {
    width: 100%;
    margin: 20px auto 0;
    padding: 25px;
  }
  .lease_info h2{
    white-space: nowrap !important;
  }
  .lease_info hr{
    margin: 20px 0 0 0px !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
