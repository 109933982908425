.careers .button2 button {
  margin-top: -90px;
  position: relative;
}

.careers .banner h1 {
  margin-top: -50px;
}

.career_life img {
  height: 500px;
  object-fit: cover;
}

.career_life .text {
  height: 500px;
  display: flex;
  align-items: center;
}

.career_life .text h3 {
  font-size: 30px;
  font-family: "Playfair Display", serif;
  font-weight: 700;
}

.career_life .text p {
  margin-top: 20px;
}

.career_life hr,
.career_do hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 70px;
  opacity: 1;
  margin-left: auto;
}

.career_life h2,
.career_do h2 {
  color: #d2ab66;
  font-size: 55px;
  /* font-weight: 600; */
  margin-right: 10px;
  white-space: nowrap;
}

.career_do img {
  height: 400px;
  object-fit: cover;
}

.career_do p {
  margin: 20px 0;
  font-size: 18px !important;
}

.careers .join h4 {
  font-size: 35px;
  color: #d2ab66;
}

.careers .join .button2 button {
  margin-top: 30px !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .careers .banner h1 {
    font-size: 25px !important;
    margin-top: -90px;
  }
  .careers .button2 button{
    margin-top: -65px;
  }
  .career_life img {
    height: 300px;
  }
  .career_life .text {
    height: fit-content;
    padding: 20px 0;
  }
  .career_life .rev {
    flex-direction: column-reverse;
  }
  .career_life .text p {
    margin-top: 10px;
  }
  .career_do img {
    height: 220px;
  }
  .career_do p {
    margin: 15px 0 !important;
    font-size: 16px !important;
  }
  .careers .join h4 {
    font-size: 23px;
  }
  .career_life hr {
    display: none;
  }
  .career_do h2 {
    white-space: nowrap !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .career_life img {
    height: 400px;
  }
  .career_life .text {
    height: 400px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
