.mall_services .card img {
  height: 60px;
  object-fit: contain;
}

.mall_services .card h3 {
  font-size: 18px;
  margin-top: 15px;
  font-weight: 600;
}

.mall_services .nav-pills .nav-link {
  font-size: 25px;
  background: none;
  font-family: "Playfair Display", serif;
  color: #000;
  font-weight: 700;
  text-align: center;
}

.mall_services .nav-pills .nav-link.active {
  color: #d2ab66;
}

.mall_services #left-tabs-example hr {
  border-top: 2px dotted #000;
  opacity: 1;
}

.mall_services .main-pad hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 0px;
  opacity: 1;
  margin-left: auto;
}

.mall_services h2 {
  color: #d2ab66;
  margin-right: 20px;
  white-space: nowrap;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .mall_services .container {
    width: 100% !important;
  }
  .mall_services .card h3 {
    font-size: 13px !important;
    margin-top: 10px;
    margin-bottom: 20px !important;
  }
  .mall_services .nav-pills .nav-link {
    font-size: 20px;
  }
  .mall_services h2{
    white-space: nowrap !important;
  }
  .mall_services .card img {
    height: 40px;
  }
  .mall_services .nav-pills hr{
    margin: 5px 0 0 0 !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
