.brands_category .category-filter {
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  height: 60px;
  position: relative;
}

.brands_category .category-filter .fa-times {
  display: none;
}

.brands_category .category-filter .form-select {
  border: 3px solid #d2ab66;
  border-radius: 0;
  height: 50px;
  width: 400px;
  font-weight: 600;
}

.brand_view button {
  font-size: 23px !important;
  font-weight: 700;
  color: #000 !important;
  font-family: "Playfair Display", serif !important;
  display: flex;
  border: 1px solid #dbb85c !important;
  width: auto !important;
  padding: 0 30px !important;
  height: 65px !important;
  justify-content: center;
  align-items: center;
  margin: 20px auto;
  letter-spacing: 1px;
}

.az-tab ul {
  justify-content: center;
  border: none;
}

.az-tab ul li button {
  border: none !important;
  color: #000;
  font-size: 16px !important;
  font-weight: 700;
}

.az-tab ul li button.active,
.az-tab ul li button:hover {
  color: #d2ab66 !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .brand_view button {
    font-size: 18px !important;
    padding: 0 20px !important;
    height: 40px !important;
  }
  .brands_category .category-filter {
    display: grid !important;
    height: fit-content !important;
    width: 75% !important;
  }
  .brands_category .category-filter .form-select {
    width: 100% !important;
    height: 40px;
  }
  .brands_category .category-filter .container {
    padding: 0 !important;
  }
  .custom-select,
  .input-wrapper,
  .brands_category .category-filter {
    width: 100% !important;
    padding: 10px !important;
  }
  .brands_category .category-filter input{
    padding: 0;
  }
  .button-group-select button {
    padding:0 10px !important;
    font-size: 13px !important;
    height:unset !important
  }
  .input-wrapper input{
    padding: 0 !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}

.main-select {
  padding: 0 150px !important;
}

.button-group-select {
  display: inline-flex;
  gap: 0;
  margin-top: 0px;

  text-align: center !important;
}

.button-group-select button {
  border: none !important;
  padding: 15 !important;
  background-color: transparent;
  font-size: 18px;
  font-family: Avenir !important;
  cursor: pointer;
}

.button-group-select button:hover {
  color: #d2ab66 !important;
}

.input-wrapper {
  display: flex;
  align-items: center;
  border: 3px solid #d2ab66;
  border-radius: 4px;
  padding: 4px;
  margin-bottom: 10px; /* Adjust as needed */
  width: 30%; /* Set the width to 25% */
}

.input-wrapper input {
  flex: 1;
  color: #000 !important;
  border: none;
  padding: 8px; /* Adjust as needed */
  font-family: Avenir !important;
  outline: none; /* Remove the default focus border */
  width: 90%; /* Set the width to 90% for most of the available space */
}

.search-icon {
  margin-left: 8px; /* Adjust as needed */
  color: #000; /* Icon color */
  cursor: pointer;
  font-size: 25px;
}

.custom-select {
  display: block;
  width: 30%; /* Set the width to 25% for 25% of the left side */
  border: 3px solid #d2ab66;
  border-radius: 4px;
  font-family: Avenir;
  padding: 14px;
  margin-bottom: 10px; /* Adjust as needed */
  background-color: #fff; /* Background color for the select element */
  font-size: 15px;
  outline: none; /* Remove the default focus border */
}

select option {
  background-color: #fff;
}

.middle-container {
  display: flex;
  justify-content: center; /* Horizontally center elements */
  align-items: center; /* Vertically center elements */
  flex-wrap: wrap; /* Allow elements to wrap to the next line */
  gap: 10px; /* Adjust as needed */
}
