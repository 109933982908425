.offers_packages hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 0px;
  opacity: 1;
  margin-left: auto;
}

.offers_packages h2 {
  color: #d2ab66;
  font-size: 25px;
  /* font-weight: 600; */
  margin-right: 20px;
  white-space: nowrap;
}

.offers_packages .form1 h2 {
  white-space: unset;
}

.offers_packages .card {
  height: 350px;
}

.offers_packages .card img {
  height: 200px;
}

.offers_packages .card1 h3 {
  font-size: 20px;
}

.offers_packages .card1 h4 {
  font-size: 16px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .offers_packages .card {
    height: 330px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
