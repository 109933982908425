.about_card img {
  height: 500px;
  object-fit: cover;
}

.about_card .text {
  border: none !important;
}

.about_card .text {
  height: 500px;
  display: flex;
  align-items: center;
  padding: 0 40px;
}

.about_card .text hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 0px;
  opacity: 1;
  margin-left: auto;
}

.about_card .text h2 {
  color: #d2ab66;
  /* font-weight: 600; */
  margin-right: 20px;
  white-space: nowrap;
}

.about_card .text p {
  margin: 20px 0 !important;
  text-align: left;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .about_card img {
    height: 300px;
  }
  .about_card .text {
    height: 220px;
    padding: 20px 0 0;
  }
  

}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
