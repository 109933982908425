.corporate_enquiry img {
  height: 500px;
  object-fit: cover;
  border-radius: 0 200px;
}

.corporate_enquiry hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 0px;
  opacity: 1;
  margin-left: auto;
}

.corporate_enquiry h2 {
  color: #d2ab66;
  /* font-weight: 600; */
  margin-right: 20px;
  white-space: nowrap;
}

.corporate_enquiry .form {
  width: 75%;
}

.corporate_enquiry .form label {
  font-size: 18px;
}

.corporate_enquiry .form input {
  border: none;
  border-bottom: 2px solid #d2ab66;
  margin-bottom: 30px;
}

.corporate_enquiry .button2 button {
  margin: unset;
  color: #fff !important;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .corporate_enquiry .form {
    width: 100%;
    margin-bottom: 20px;
  }
  .corporate_enquiry .form input {
    margin-bottom: 20px;
  }
  .corporate_enquiry .form label {
    font-size: 16px;
  }
  .corporate_enquiry img {
    height: 330px;
  }
  .corporate_enquiry h2{
    white-space: nowrap !important;
    margin-right: 0;
  }
}


/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
