@font-face {
  font-family: Avenir;
  src: url(../../assets/AvenirNext.otf);
}
.search-form {
  right: 200px;
  top: 200px;
}

.search-field {
  background-color: transparent;
  background-image: url(https://wp-themes.com/wp-content/themes/twentythirteen/images/search-icon.png);
  background-position: 5px center;
  filter: invert(100%);
  background-repeat: no-repeat;
  background-size: 24px 24px;
  border: none;
  cursor: pointer;
  height: 40px;
  margin: 3px 0;
  padding: 0 0 0 34px;
  position: relative;
  -webkit-transition: width 400ms ease, background 400ms ease;
  transition: width 400ms ease, background 400ms ease;
  width: 0px;
  cursor: pointer;
}

.search-field:focus {
  background-color: #000;
  border: 2px solid #c3c0ab;
  cursor: text;
  outline: 0;
  width: 130px;
  color: #000;
}
.search-form .search-submit {
  display: none;
}

.navbar {
  /* background-color: rgba(0,0,0,0.4) !important; */
  /* background-color: #f4f1e7; */
  width: 100%;
  height: 160px;
  z-index: 100;
}

.navbar .top #basic-nav-dropdown {
  width: 250px;
  background-color: #fff;
  border-radius: 20px;
  height: 36px;
  margin-top: 20px;
  border: none;
  text-align: center;
  font-size: 14px;
  color: #000 !important;
}

.navbar .social-media a img {
  width: 20px;
  height: 20px;
}

.navbar .page-links {
  z-index: 100;
}

.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
  top: 60px;
  width: 250px;
  left: 20px;
  border: none;
  padding-top: 20px;
}

.navbar-expand .navbar-nav .dropdown-menu a {
  margin-bottom: 20px;
}

.navbar .navbar-brand {
  z-index: 1;
}
.navbar .navbar-expand-lg .navbar-collapse {
  z-index: 1;
}

.navbar-nav {
  justify-content: space-between;
}

.navbar .logo img {
  height: 80px !important;
}

.navbar .navbar-brand img {
  width: 245px;
  height: 55px;
  object-fit: contain;
}

.navbar .top .nav-link {
  margin-top: 20px;
}

.navbar .top svg {
  font-size: 25px;
  color: #fff;
  margin-top: -3px;
}
.navbar .nav-link {
  color: #fff !important;
  font-size: 15px;
  margin: 0 20px;
  font-family: Avenir;
  /* font-weight: 700 !important; */
  letter-spacing: 2px;
  white-space: nowrap;
  height: fit-content;
}

.navbar .bottom .nav-link:hover {
  color: #c7982e !important;
}

.navbar .social-media a {
  font-size: 25px;
  color: #fff;
  margin: 0px 15px 0 0px;
}

.navbar .search-wish-bag a {
  margin-left: 10px;
  font-size: 20px;
  color: #fff;
  top: -7px;
  position: relative;
}

.search_modal .modal-body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.search_modal .modal-content {
  margin-top: 100px;
  height: 500px;
  background-color: rgba(0, 0, 0, 0.8);
}

.search_modal .modal-header .btn-close {
  filter: invert(100%);
}

.search_modal .input-wrap {
  width: 600px;
  height: 60px;
  margin: 0 auto;
  margin-top: -70px;
  border: 2px solid #fff;
  background: transparent;
  border-radius: 50px;
}

.search_modal .input-wrap i {
  color: #fff;
}

.search_modal #product-search {
  background: transparent;
  color: #fff;
}

.search_sector button {
  padding: 0;
  margin-left: 5px;
}

.search_sector button svg {
  color: #fff;
  font-size: 20px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  /* .sc-JrDLc.toggled .sc-JrDLc {
    display: none !important;
  }
  .sc-JrDLc {
    position: absolute !important;
    right: 0 !important;
    height: 900px !important;
  }
  .sc-JrDLc.toggled {
    right: 0 !important;
    position: absolute !important;
    background-color: transparent !important;
    height: 50px !important;
  } */
  .navbar-mobile img {
    width: 180px;
    object-fit: contain;
    padding: 10px;
  }
  .navbar-mobile .social-media img {
    width: 25px;
    padding: 0 5px;
  }
  .navbar-mobile .sidenav .social-media a{
    margin:0;
    padding: 0 5px;
    font-size: 20px;
  }
  /* .head-text img {
    width: 120px;
  } */
  .navbar {
    height: fit-content;
  }
  /* .navbar-mobile {
    position: fixed;
    top: 0;
    background-color: #000;
    z-index: 1001;
    width: 100%;
    height: 80px;
  } */
  /* .hQSxZz {
    margin: 0 15px !important;
  } */

  /* .navbar .dropdown-menu[data-bs-popper] {
    left: 20px !important;
  } */
  .navbar-mobile .sidenav a {
    font-size: 14px;
    display: flex;
    padding: 0 10px;
    align-items: center;
    color: #fff !important;
    border-radius: 10px;
    margin: 20px;
    font-family: Avenir;
    letter-spacing: 2px;
    white-space: nowrap;
  }
  .navbar-mobile .sidenav .dropdown a {
    margin: 0 25px;
  }
  .navbar-mobile .sidenav .dropdown .dropdown-menu a {
    margin: 0 25px 0 0 !important;
    font-size: 10px;
  }
  .navbar-mobile .dropdown-menu.show {
    width: 81%;
    margin: 0px 25px;
  }
  .navbar-mobile .social-media {
    position: absolute;
    right: 20px;
  }
  /*========= Menu button =========*/
  .bar1,
  .bar2,
  .bar3 {
    width: 25px;
    height: 3px;
    background-color: #fff;
    margin: 5px 0;
    border-radius: 3px;
    transition: 0.2s;
  }
  .navbar-mobile .container {
    display: block;
    width: 35px;
    opacity: 0.8;
    transition: 0.2s;
    z-index: 100;
    position: absolute;
    right: 25px;
    top: 25px;
  }

  .navbar-mobile .container.close {
    top: 18px;
  }

  .navbar-mobile .container:hover {
    opacity: 1;
  }

  .close .bar1 {
    -webkit-transform: translate(0px, 12px) rotate(-45deg);
    transform: translate(0px, 12px) rotate(-45deg);
    margin: 9px 0;
  }

  .close .bar2 {
    opacity: 0;
    margin: 9px 0;
  }

  .close .bar3 {
    -webkit-transform: translate(0, -12px) rotate(45deg);
    transform: translate(0, -12px) rotate(45deg);
    margin: 9px 0;
  }

  /*========= side navigation ========= */
  .navbar-mobile .sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 100;
    top: 0;
    right: 0;
    opacity: 1;
    text-align: center;
    background-color: #000;
    overflow-x: hidden;
    padding-top: 90px;
    transition: 0.5s;
  }

  .navbar .dropdown a {
    background-color: #fff;
    color: #000 !important;
    padding: 5px 15px;
    border-radius: 50px;
    font-size: 12px;
  }

  /* The navigation menu links */
  /* .navbar-mobile .sidenav a {
    padding: 8px 8px 8px 32px;
    text-decoration: none;
    font-size: 25px;
    color: #818181;
    display: block;
    transition: 0.3s;
  }

  .navbar-mobile .sidenav a:hover {
    color: #f1f1f1;
  } */

  .navbar-mobile .open .sidenav {
    width: 70%;
  }

  .navbar-mobile .menubutton {
    position: fixed;
    background-color: #000;
    top: 0px;
    z-index: 1000;
    width: 100%;
    padding: 10px;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
  .navbar .nav-link {
    font-size: 15px;
    margin: 0 3px;
    letter-spacing: 0px;
  }
  .navbar .social-media a {
    font-size: 20px;
    margin: 0px 5px 0 0px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
