.dine_category .category-filter{
    display: flex;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    height: 60px;
    position: relative;
}


.dine_category .category-filter .fa-times{
    display: none;
}

.dine_category .category-filter .form-select{
    border:3px solid #d2ab66;
    border-radius: 0;
    height: 50px;
    width:400px;
    font-weight: 600;
}

.dine_view button{
    font-size: 23px !important;
    font-weight: 700;
    color: #000 !important;
    font-family: "Playfair Display", serif !important;
    display: flex;
    border: 1px solid #dbb85c !important;
    width:auto !important;
    padding:0 30px !important;
    height:65px !important;
    justify-content: center;
    align-items: center;
    margin: 0px auto 50px;
    letter-spacing: 1px;
}

/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
    .dine_view button{
        font-size: 18px !important;
        padding: 0 20px !important;
        height: 40px !important;
    }
    .dine_category .category-filter{
        display: grid !important;
        height: fit-content !important;
        width: 75% !important;
      }
      .dine_category .category-filter .form-select{
        width: 100% !important;
        height: 40px;
      }
      .dine_category .category-filter .container{
        padding: 0 !important;
      }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
.dine_detail .brands_category button {
    display:block;
}