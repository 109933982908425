.category_card .category-filter{
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  height: 60px;
  position: relative;
}


.category_card .category-filter .fa-times{
  display: none;
}

.category_card .category-filter .form-select{
  border:3px solid #d2ab66;
  border-radius: 0;
  height: 50px;
  width:400px;
  font-weight: 600;
}

.category_card .card3 {
  padding: 0px 0 30px !important;
}
.category_card .card3 .card {
  margin: 0;
}
.category_card .card3 .card h3 {
  display: none;
}
.category_card .card3 .text {
  text-align: center;
  position: absolute;
  left: 30px;
  bottom: 15px;
}

.category_card hr {
  border-top: 3px solid #d2ab66;
  width: -webkit-fill-available;
  margin: 35px 0 0 10px;
  opacity: 1;
}

.category_card h2 {
  color: #dbb85c;
  white-space: nowrap;
  margin-right: 10px;
}
/* Extra small devices (portrait phones, less than 576px) */
@media (max-width: 575.98px) {
  .category_card .card3 .card img {
    margin-top: -50px;
  }
  .category_card .card3 {
    padding: 0px 0 10px !important;
  }
  .category_card .card3 .text {
    left: 15px;
    bottom: 0px;
  }
  .category_card .category-filter{
    display: grid !important;
    height: fit-content !important;
  }
  .category_card .category-filter .form-select{
    width: 100% !important;
    height: 40px;
  }
  .category_card .category-filter .container{
    padding: 0 !important;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
}

/* Medium devices (tablets, 768px and up) */
@media (min-width: 768px) and (max-width: 991.98px) {
}

/* Large devices (desktops, 992px and up) */
@media (min-width: 992px) and (max-width: 1439.98px) {
}

/* Extra large devices (large desktops, 1200px and up) */
@media (min-width: 1200px) {
}
